<template>
  <div>
    <ReferenceNavBar />
    <br />
    <v-container class="LoginContainer" fill-height fluid>
      <v-row align="center" justify="center">
        <v-col class="LoginForm pa-10" cols="8" md="6">
          <h1 align="center">Войти в систему<br />ЦИССК ДЖВ ОАО «РЖД»</h1>
          <UserLoginForm :submitForm="submitAuthData" />
        </v-col>
      </v-row>
      <NotificationContainer />
    </v-container>
  </div>
</template>

<script>
import UserLoginForm from '@/components/UserLoginForm';
import { mapActions, mapGetters } from 'vuex';
import NotificationContainer from '@/components/notification/NotificationContainer.vue';
import ReferenceNavBar from '@/components/reference/ReferenceNavBar';

export default {
  components: {
    NotificationContainer,
    UserLoginForm,
    ReferenceNavBar
  },
  computed: {
    ...mapGetters('auth', ['user'])
  },
  methods: {
    async submitAuthData(username, password) {
      try {
        await this.login({ username, password });
        let nextPage = 'operator';
        if (this.user.defaultPage && this.user.defaultPage.length > 0) {
          nextPage = this.user.defaultPage;
        }
        this.$router.push({ name: nextPage });
      } catch (ex) {
        console.log(ex);
      }
    },
    ...mapActions('auth', ['login'])
  }
};
</script>
<style scoped>
.LoginContainer {
  font-family: RussianRail;
  background-color: rgba(255, 255, 255, 0.2);
}
.LoginForm {
  border-radius: 3%;
  background-color: white;
}
</style>
